import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import {
  createTheme,
  Shadows,
  ThemeProvider,
  CssBaseline,
  Box
} from '@mui/material';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
  },
  palette: {
    mode: 'dark',
    background: {
      default: '#212121',
    },
    primary: {
      main: '#E1AB00',
    },
    secondary: {
      main: '#212121',
    },
    text: {
      primary: '#EEE',
      secondary: '#CCC',
    },
  },
  shadows: Array(25).fill('none') as Shadows, 
});

export default function App() {

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
      <CssBaseline />
      <Box sx={{display: 'flex', justifyContent: 'center', p: 2}}>
        <Routes>
          <Route path="/" element={<Home />} />
        </Routes>
      </Box>
      </BrowserRouter>
    </ThemeProvider>
  );
}
