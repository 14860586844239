import { Divider, Paper, Typography } from "@mui/material";
import { Channel } from "../pages/Home";
import dayjs from "dayjs";

export default function ChannelData({ channel }: { channel: Channel }) {
  return (
    <Paper sx={{ width: 800, mt: 4, p: 2 }} elevation={2}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {channel.channelName.slice(1)}
      </Typography>

      <Divider sx={{ mb: 2, bgcolor: 'primary.main' }} />

      <Typography variant="h6" sx={{ mb: 2 }}>
        Log Types: {channel.logType ? "Enabled" : "Disabled"}
      </Typography>

      {channel.catchAttempts.map((attempt) => (
        <Typography variant="h6" sx={{ mb: 2 }}>
          {attempt.pokemon} - {attempt.ball} - {attempt.caught ? "Caught" : "Escaped"} - {attempt.account?.twitchName || "Unknown"} - {dayjs(attempt.time).format("MM/DD/YYYY HH:mm:ss")}
        </Typography>
      ))}
    </Paper>
  );
}
