import { Divider, Paper, Typography } from "@mui/material";
import { Account } from "../pages/Home";
import dayjs from "dayjs";

export default function AccountData({ account }: { account: Account }) {
  return (
    <Paper sx={{ width: 800, mt: 4, p: 2 }} elevation={2}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {account.twitchName}
      </Typography>

      <Divider sx={{ mb: 2, bgcolor: "primary.main" }} />

      {account.catchAttempts.reverse().map((attempt) => (
        <Typography variant="h6" sx={{ mb: 2 }}>
          {attempt.pokemon} {attempt.shiny ? "Shiny" : ""} {attempt.stone ? "Stone" : ""} {attempt.gift ? "Gift" : ""} - {attempt.ball} - {attempt.caught ? "Caught" : "Escaped"} - {attempt.channel?.channelName.slice(1) || "Unknown"} - {dayjs(attempt.time).format("MM/DD/YYYY HH:mm:ss")} 
        </Typography>
      ))}
    </Paper>
  );
}
