import {
  Paper,
  TextField,
  Typography,
  InputAdornment,
  Box,
  Button,
  Switch,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useState } from "react";
import axios from "axios";
import AccountData from "../components/AccountData";
import ChannelData from "../components/ChannelData";

type CatchAttempt = {
  _id: string;
  pokemon: string;
  caught: boolean;
  time: Date;
  ball: string;
  account: Account;
  channel: Channel;
  shiny?: boolean;
  stone?: boolean;
  gift?: boolean;
};

export type Account = {
  _id: string;
  twitchId: number;
  twitchName: string;
  catchAttempts: CatchAttempt[];
};

export type Channel = {
  _id: string;
  channelName: string;
  logType: boolean;
  catchAttempts: CatchAttempt[];
};

export default function Home() {
  const [inputMode, setInputMode] = useState<"Account" | "Channel">("Account");
  const [twitchName, setTwitchName] = useState("");

  const [account, setAccount] = useState<Account>();
  const [channel, setChannel] = useState<Channel>();
  const [error, setError] = useState("");

  function handleSearch(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (twitchName === "") return;

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/${inputMode.toLowerCase()}/${twitchName}`)
      .then((res) => {
        setError("");
        if (inputMode === "Account") {
          setAccount(res.data);
          setChannel(undefined);
        } else {
          setChannel(res.data);
          setAccount(undefined);
        }
      })
      .catch((err) => {
        setAccount(undefined);
        setError(`${inputMode} ${twitchName} not found.`);
      });
  }

  return (
    <Paper
      sx={{
        width: 1000,
        display: "flex",
        alignItems: "center",
        p: 2,
        flexDirection: "column",
      }}
    >
      <Typography variant="h3" sx={{ mb: 8 }}>
        Pokemon Community Game Stats
      </Typography>

      <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
        <Typography variant="body1" sx={{ mr: 2 }}>
          Account
        </Typography>
        <Switch checked={inputMode === "Channel"} onChange={() => setInputMode(inputMode === "Account" ? "Channel" : "Account")} />
        <Typography variant="body1" sx={{ ml: 2 }}>
          Channel
        </Typography>
      </Box>

      <form onSubmit={handleSearch}>
        <Box sx={{ width: 400, mb: 2, display: "flex", alignItems: "center" }}>
          <TextField
            id="outlined-basic"
            label="Twitch Name"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={twitchName}
            onChange={(e) => setTwitchName(e.target.value)}
          />

          <Button type="submit" variant="contained" sx={{ ml: 2 }}>
            Search
          </Button>
        </Box>
      </form>

      {error && (
        <Paper sx={{ width: 800, mt: 4, p: 2 }} elevation={2}>
          <Typography variant="h4" textAlign={'center'}>
            {error}
          </Typography>
        </Paper>
      )}

      {account && (
        <AccountData account={account} />
      )}

      {channel && (
        <ChannelData channel={channel} />
      )}
    </Paper>
  );
}
